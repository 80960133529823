import React, { useContext, useMemo } from 'react';
import { Box, Button, Typography, IconButton, Chip, Grid, LinearProgress, Badge, Dialog, Toolbar, AppBar, List, ListItemButton, Divider, ListItemText, Slide } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import { LeadHeader } from './LeadHeader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import API from '../../../reactelements/src/config/api';
import { renderDate } from './helpers/dateHelpers';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { LeadListResponse } from './types';
import { CRMData, CRMChangeHistory } from 'reactelements/src/modules/ViewEditCrm/types';
import { useDispatch, useSelector } from 'react-redux';
import { loadCrmDataForEditing, loadCrmItemListAction, updateLeadCustomStatusesAction } from '../../Models/NewCRM/actions';
import { CustomModelFieldConfig } from 'reactelements/src/modules/GenericCustomModelFields/CustomModelFieldsForm';
import { createRingDetails, offlineCRMMockEvent } from './helpers/crmHelpers';
import { EmpplanSnackbarContext } from '../../../reactelements/src/modules/Snackbar';
import LeadStatusSelect from 'reactelements/src/modules/ViewEditCrm/LeadStatusSelect';
import { CountryList } from 'reactelements/src/pages/MyEvents/components/types';
import { ICustomStatuses } from 'reactelements/src/modules/SideBarContentForEvents/types';
import { useSwipeable } from 'react-swipeable';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';
import { DetectOnline } from '../../../reactelements/src/hooks/detectOnline';
import { loadProjectAction, loadProjectCustomStatus } from '../../Models/NewProject/actions';
import { createNewEventWrapperForDexie } from '../../Models/NewEvent/helpers';
import { createNewEventAction } from '../../Models/NewEvent/actions';
import { loadCustomModelFieldListAction } from '../../Models/NewCustomModelField/actions';
import LeadDetailTransactionList from './LeadDetailTransactionList';
import { newEventDb } from '../../Models/NewEvent/newEventsDexie';
import axios from 'axios';
import DynTableConst from '../../Services/Constants';
import { clearLoadCustomReportListAction, loadCustomReportListAction } from '../../Models/NewCustomReport/actions';
import HistoryIcon from '@mui/icons-material/History';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CommonHistory from 'reactelements/src/modules/CommonHistory';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


interface CrmHistoryEntry {
    changedField: fieldHistory[]
    date?: string | Date; // adjust if date is always string or Date
}

interface  fieldHistory{   fieldName?: string;}

export const generatePOS = (crmData: CRMData | null) => {
    return {
        address: {
            ...crmData?.address,
            country: crmData?.address?._country,
            company: crmData?.address?.company,
            streetnumber: crmData?.address?.streetNumber,
            additional: crmData?.address?.additionalAddressline,
            postal: crmData?.address?.zipCode,
            city: crmData?.address?.city,
        },
        name: crmData?.address?.streetName + ' ' + crmData?.address?.streetNumber || '' + crmData?.address?.city || '',
        location: [crmData?.address?.loc?.[0] || 0, crmData?.address?.loc?.[1] || 0],
        distributionAreas: [],
        genericFields: {},
        active: true,
        associatedUser: [],
        locationGroups: [],
        locationContacts: [],
        associatedProductGroups: [],
    };
};

export const LeadDetail = () => {
    const location = useLocation();
    const { isHybridSync } = React.useContext(PWASyncControlContext);
  
    const queryParams = new URLSearchParams(location.search);
  
    // Convert the URLSearchParams to an object
    let params = Object.fromEntries(queryParams.entries());
  
    const navigate = useHistory();
    const dispatch: any = useDispatch();
    const { t } = useTranslation();
    const { isOnline, isConnectionStable } = DetectOnline();
    const snackbarTools = useContext(EmpplanSnackbarContext);
    const { leadId, projectId } = useParams<{ leadId: string; projectId: string }>();
    const [loading, setLoading] = React.useState(false);
    const [crmData, setCrmData] = React.useState<CRMData | null>(null);
    const [allLabelData, setAllLabelData] = React.useState<ICustomStatuses[]>([]);
    const [relatedCrmData, setRelatedCrmData] = React.useState<LeadListResponse | null>(null);
    const [relatedCrmIndex, setRelatedCrmIndex] = React.useState(0);
    const [crmIndex, setCrmIndex] = React.useState(parseInt(params?.index));
    const [skip, setSkip] = React.useState(parseInt(params?.skip));
    const [limit, setLimit] = React.useState(parseInt(params?.limit));
    const [totalCount, setTotalCount] = React.useState(0);
    const [leadData, setLeadData] =  React.useState<LeadListResponse['items']>([]);
    
    const [relatedGenricFields, setRelatedGenricFields] = React.useState<CustomModelFieldConfig[]>([]);
    const [addNewCRMEntry, setAddNewCRMEntry] = React.useState<boolean>(false);
    const [queryString, setQueryString] = React.useState<string>();

    const [newTransactionDisabled, setNewTransactionDisabled] = React.useState<boolean>(false);
    const [transactionData, setTransactionData] = React.useState([]);

    const [totalCountMsg, setTotalCountMsg] = React.useState(0);
    const [unreadCount, setUnreadCount] = React.useState(0);
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    

    const customReports: any = useSelector((store: any) => store?.customReport?.loadedCustomReports);
    const transactionReportConfig =
        Array.isArray(customReports) && customReports.find(cr => cr?.customReport?.type === 'sales');
    
    const fetchLeadDataforNavigate = async (query:string ,type:string,index:number,currentskip:number) => {
        try {
            if (!isHybridSync) return;
            const resp = await API.get(
                `/crmleadlist?${query}`
            );
            const jsonResp = await resp.json();
            if (jsonResp.items && Array.isArray(jsonResp.items)) {
                // setLeadData(jsonResp.items);
                //setLeadData(prevLeads => (jsonResp.items));
                if(type == 'next'){
                    navigate.replace(`/leadDetail/${jsonResp.items[0]._id}/${projectId}?index=${index}&queryCount=true&skip=${currentskip}&limit=${limit}&${queryString}`)
                }else{
                    if(currentskip == 0){
                        navigate.replace(`/leadDetail/${jsonResp.items[0]._id}/${projectId}?index=${index}&queryCount=true&skip=${currentskip}&limit=${limit}&${queryString}`)
                    }else{
                        navigate.replace(`/leadDetail/${jsonResp.items[jsonResp.items.length - 1]._id}/${projectId}?index=${index}&queryCount=true&skip=${currentskip}&limit=${limit}&${queryString}`)
                    }
                    
                    
                }
                
            } else {
                console.error('error loading projects', 'invalid resp', jsonResp);
            }
        } catch (error) {
            console.error('error loading projects', error);
        } finally {
        }
    }

    const fetchLeadData = async (reset = false) => {
        try {
            if (!isHybridSync) return;
            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(([key]) => !["index", "skip", "limit"].includes(key))
              );
              const queryString = new URLSearchParams(filteredParams).toString();

            console.log(queryString); 
            setQueryString(queryString)
            if (!projectId) return;
            const resp = await API.get(
                `/crmleadlist?queryCount=true&skip=${skip}&limit=${limit}&${queryString}`
            );
            const jsonResp = await resp.json();
            if (jsonResp.items && Array.isArray(jsonResp.items)) {
                // setLeadData(jsonResp.items);
                setLeadData(prevLeads => (reset ? jsonResp.items : [...prevLeads, ...jsonResp.items]));
                setTotalCount(jsonResp.count)
                
            } else {
                console.error('error loading projects', 'invalid resp', jsonResp);
            }
        } catch (error) {
            console.error('error loading projects', error);
        } finally {
        }
    };

    React.useEffect(() => {
        fetchLeadData();
    }, []);
   

    const handleRelatedCrmDataChange = (index: number) => {
        setRelatedCrmIndex(index);
        if (index === relatedCrmData?.count) {
            setAddNewCRMEntry(true);
        } else {
            if (addNewCRMEntry) {
                setAddNewCRMEntry(false);
            }
            navigate.replace(`/leadDetail/${relatedCrmData?.items[index]._id}/${projectId}`);
        }
    };

    const loadCrmData = async () => {
        // this loads the crm
        setLoading(true);
        let result = null;
        try {
            if (isHybridSync) {
                const response = await API.get(`/crmleadlist?queryCount=true&skip=0&crmId=${leadId}&projectId=${projectId}`)
                result = await response.json()
            } else {
                result = await loadCrmItemListAction(dispatch, { crmId: leadId, projectId })
            }
            if (result?.items[0]?._id) {
                // console.log('loaded', result);
                setCrmData(result?.items[0]);
                if (!relatedCrmData) loadRelatedCrmData(result?.items[0]);
                const extractedCrmData = extractCRMData(result?.items[0] as CRMData);
                loadTransactionsData(result?.items[0])
                dispatch(loadCrmDataForEditing(extractedCrmData));
            } else {
                throw new Error('invalid json response');
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const loadRelatedCrmData = async (crmData: CRMData) => {
        try {
            let jsonResp: any = { count: 0, items: [] };
            if (isHybridSync) {
                let address = `&streetnumber=${crmData?.address?.streetNumber}`;
                const resp = await API.get(
                    `/crmleadlist?queryCount=true&skip=0&limit=100&projectId=${projectId}` +
                        `&streetname=${crmData?.address?.streetName}&streetnumberappendix=${crmData?.address?.streetAppendix}` +
                        `&city=${crmData.address.city}&zipCode=${crmData.address.zipCode}${address}`,
                );
                jsonResp = await resp.json();
            } else {
                jsonResp = await loadCrmItemListAction(dispatch, {
                    projectId,
                    streetnumber: crmData?.address?.streetNumber ?? 0,
                    streetname: crmData?.address?.streetName ?? '',
                    city: crmData.address.city ?? '',
                    zipCode: crmData.address.zipCode ?? '',
                    streetAppendix: crmData?.address?.streetAppendix ?? '',
                });
            }
            if (jsonResp.items && Array.isArray(jsonResp.items)) {
                jsonResp.items.sort((a: any, b: any) => (a._id === leadId ? -1 : 0));
                setRelatedCrmData(jsonResp);
            } else {
                console.error('error loading projects', 'invalid resp', jsonResp);
            }
        } catch (error) {
            console.error('error loading related crm entries', error);
        }
    };

    const fetchLabelsForProject = async () => {
        try {
            if (isHybridSync) {
                API.get(`/projects/customstatus/filter?&skip=0&project_id=${projectId}&limit=500`)
                    .then(res => res.json())
                    .then(jsonData => {
                        if (jsonData?.success && Array.isArray(jsonData.data?.customs)) {
                            const crmLabels = jsonData.data?.customs?.filter((l: ICustomStatuses) =>
                                l.usedFor.includes('CRM'),
                            );
                            setAllLabelData(crmLabels);
                        }
                    })
                    .catch(err => {
                        console.error('error loading labels', err);
                    });
            } else {
                loadProjectCustomStatus({ projectId })(dispatch).then((data: any) => {
                    if (Array.isArray(data)) {
                        const crmLabels = data?.filter((l: ICustomStatuses) => l.usedFor.includes('CRM'));
                        setAllLabelData(crmLabels);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const loadCRMGenricFieldsPerProject = async () => {
        try {
            if (isHybridSync) {
            const data: any = await API.getReqestWithParams({
                url: '/api/customModelField/field',
                params: { model: 'CRM', project: projectId },
            });
            setRelatedGenricFields(data);
            } else {
                const data = await loadCustomModelFieldListAction({ pathname: 'CRM', projectId })(dispatch);
                setRelatedGenricFields(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    //get Transaction data
    const loadTransactionsData = async (crmData: CRMData) => {
        if (isHybridSync) {
            crmData.eventId.map((item:any) => {
                try {
                    API.get(`/salesItems?eventId=${item._id}`)
                        .then(res => res.json())
                        .then(jsonData => {
                            if (Array.isArray(jsonData)) {
                                setTransactionData(prevData => [...prevData, ...jsonData] as []);
                            }
                        });
                } catch (error) {
                    console.error(error);
                }
            })
        }
    }

    //get chat channel related to leadid
    const loadChatMessangerData = async () => {
        const obj = JSON.stringify({
            contextObject: 'crm',
            contextObjectId: leadId,
        });

        const res = await axios.get(`${DynTableConst.EMPPLAN_HOST}/chatchannel?context=${obj}`);
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
            const response = await API.get(`/chatmessages?channel=${res.data[0]._id}`);
            const newMessages = await response.json();

            setTotalCountMsg(Object.keys(newMessages).length);
            setUnreadCount(countUnreadMessages(newMessages, window?.userabstraction?._id));
        } 
    }

    //get unread message count
    function countUnreadMessages(messages:any, userId:any) {
        return Object.values(messages).filter((msg:any) => !msg.readBy.includes(userId)).length;
    }

    React.useEffect(() => {
        fetchLabelsForProject();
    }, []);

    React.useEffect(() => {
        setCrmData(null);
        loadCrmData();
        loadChatMessangerData();
    }, [leadId]);

    React.useEffect(() => {
        loadCRMGenricFieldsPerProject();
        dispatch(loadCustomReportListAction({ projectId: projectId }));
        return () => {
            dispatch(clearLoadCustomReportListAction());
        };
    }, [projectId]);

    // cause edit form doesn't need some populated values, it will handle it internally
    const extractCRMData = (notExtractedData: CRMData): CRMData => {
        const payload = {
            ...notExtractedData,
            address: {
                ...notExtractedData.address,
                streetNumber: notExtractedData?.address?.streetNumber,
                streetAppendix: notExtractedData?.address?.streetAppendix,
                _country: (notExtractedData.address._country as CountryList)?._id ?? notExtractedData.address._country,
            },
            customStatuses: notExtractedData.customStatuses?.map((status: string | ICustomStatuses) =>
                typeof status === 'string' ? status : status._id,
            ),
        };
        // payload.status = null;
        return payload;
    };

    const openCustomDataForm = (mode?: string) => {
        // update to store
        // navigate to crmedit page
        if (!crmData) return;
        const data = extractCRMData(crmData);
        if (mode === 'add') data.status = null;
        dispatch(loadCrmDataForEditing(data));
        navigate.push(mode ? `/crmEdit/${leadId}/${projectId}/${mode}` : `/crmEdit/${leadId}/${projectId}`);
    };

    const goToCrm = (sindex:number,type:string)=>{
        let currentskip =skip;
        setCrmIndex(sindex)
        if(sindex == totalCount){
            setCrmIndex(sindex -1)
            return
        }
        if(sindex == -1){
            setCrmIndex(sindex +1)
            return
        }
        if(sindex % 10 == 0){
            if(type == 'next'){
                currentskip = currentskip+10
            }else{
               if(currentskip == 0){
                currentskip = currentskip
               }else{
                currentskip = currentskip-10
               }
            }
            
            fetchLeadDataforNavigate(`?queryCount=true&skip=${currentskip}&limit=${limit}&${queryString}`,type,sindex,currentskip)
            return
        }
        // Reconstruct the full URL
        leadData.map((lead, index) => {
            if(index == sindex){
                console.log("swipe Lead ID",lead._id)
                //setleadId(lead?._id)
                navigate.replace(`/leadDetail/${lead._id}/${projectId}?index=${index}&queryCount=true&skip=${skip}&limit=${limit}&${queryString}`);
            }
        })
        
       
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => goToCrm(crmIndex + 1,'next'), // Move to next entry on left swipe
        onSwipedRight: () => goToCrm(crmIndex - 1,'prev'), // Move to previous entry on right swipe
        trackMouse: true
    });


    const createNewEvent = async (lead: CRMData) => {
        const data = {
            crmItem: lead,
            project_id: projectId,
            point_of_sale: generatePOS(crmData),
            uniqueCheckFailed: true,
            crmId: lead?._id,
            type: 'newEventCheck',
        };
        if (isHybridSync) {
            const res = await API.post(`/crm/checkin`, data);
            const results = await res.json();
            if (results.event) {
                navigate.push(`/event/${results.event._id}/transactionReportCRM/${leadId}`);
            } else {
                snackbarTools.createToast({ type: 'error', message: t`##MissingAreaNoLocationsInProjectCantAddEvent` });
            }
        } else {
            // offline mock crm event
            const localEventId = localStorage.getItem(leadId);
            const dexieEvent = await newEventDb.events.get(localEventId);
            if (localEventId && dexieEvent?._id) {
                await dispatch(loadProjectAction(projectId));
                navigate.push(`/event/${localEventId}/transactionReportCRM/${leadId}`);
            } else {
                const event = offlineCRMMockEvent(lead, projectId);
                localStorage.setItem(leadId, event._id);
                const dexieWrapper = createNewEventWrapperForDexie(event);
                dispatch(createNewEventAction(dexieWrapper));
                await dispatch(loadProjectAction(projectId));
                navigate.push(`/event/${event._id}/transactionReportCRM/${leadId}`);
            }
        }
    };

    const callBackAfterLeadStatusChange = (data: CRMData) => {
        // console.log('data',data.status)
        const savedData = localStorage.getItem('listData') ? JSON.parse(localStorage.getItem('listData') as string) : null;
        // console.log('savedData',savedData);
        if(savedData){
            const updatedSavedData = savedData.map((lead:any) => 
                lead._id === data._id ? { ...lead, status: data.status } : lead
            );
            console.log('updatedSavedData',updatedSavedData);
            localStorage.setItem('listData', JSON.stringify(updatedSavedData));
        }        
        setCrmData(data);
        dispatch(loadCrmDataForEditing(data as CRMData));
    };

    const customStatusIds = useMemo(() => {
        if (crmData?.customStatuses.length && typeof crmData.customStatuses[0] === 'string') {
            return crmData?.customStatuses as string[];
        } else {
            return (crmData?.customStatuses as ICustomStatuses[])?.map((status: ICustomStatuses) => status?._id);
        }
    }, [crmData?.customStatuses]);

    const lastLeadStatus = crmData?.crmChangeHistory
    ?.map((entry: CRMChangeHistory) => ({
        ...entry,
        leadStatusField: entry.changedField.find((field) => field.fieldName === "##LeadStatus")
    }))
    .filter((entry) => entry.leadStatusField && entry.date)  // Ensure both leadStatusField and date are present
    .sort((a, b) => new Date(b.date!).getTime() - new Date(a.date!).getTime())[0];  // Use non-null assertion for date

    return (
        <>
            {loading && <LinearProgress key='loading-linear' />}
            {crmData && (
                <React.Fragment key='crm-details-main'>
                    {/* Header */}

                    <LeadHeader crmData={crmData} svgPin='main' />
                    <div {...handlers} style={{ touchAction: 'pan-y' }}>
                        <Grid
                            container
                            spacing={1}
                            direction='column'
                            gap={2}
                            sx={{ minHeight: '90vh', marginTop: '120px' }}
                        >
                            {/* CRM Information */}
                            <Grid item xs={12} textAlign='center' sx={{ paddingTop: '20px !important' }}>
                                {addNewCRMEntry ? (
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        sx={{
                                            my: 2,
                                            borderRadius: 30,
                                        }}
                                        onClick={() => openCustomDataForm('add')}
                                    >
                                        {t('##AddNewClient')}
                                    </Button>
                                ) : crmData.firstName || crmData.lastName ? (
                                    <Typography color='secondary' onClick={() => openCustomDataForm()}>
                                        {crmData.firstName} {crmData.lastName}
                                        <br />
                                        {crmData.address.company}
                                    </Typography>
                                ) : (
                                    <Typography color='secondary' onClick={() => openCustomDataForm()}>
                                        {t('##Edit')}
                                    </Typography>
                                )}
                                <Box display='flex' justifyContent='center' alignItems='center'>
                                    <IconButton
                                        edge='start'
                                        color='secondary'
                                        aria-label='back'
                                        disabled={!relatedCrmData?.count || relatedCrmIndex < 1}
                                        onClick={() => {
                                            handleRelatedCrmDataChange(relatedCrmIndex - 1);
                                        }}
                                    >
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                    <Typography sx={{ color: 'white', background: '#424242', px: 1 }}>
                                        {relatedCrmIndex + 1}/{relatedCrmData?.count ?? '-'}
                                    </Typography>
                                    <IconButton
                                        edge='end'
                                        color='secondary'
                                        aria-label='back'
                                        disabled={!relatedCrmData?.count || relatedCrmIndex + 1 >= relatedCrmData?.count + 1}
                                        onClick={() => {
                                            handleRelatedCrmDataChange(relatedCrmIndex + 1);
                                        }}
                                    >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </Box>
                            </Grid>

                            {/* Action Button */}
                            <Grid
                                visibility={addNewCRMEntry ? 'hidden' : undefined}
                                item
                                xs={12}
                                container
                                justifyContent='center'
                                gap={1}
                            >
                                <Grid item xs={12} textAlign='center'>
                                    <LeadStatusSelect
                                        row={crmData}
                                        cbAfterUpdate={callBackAfterLeadStatusChange}
                                        projectId={projectId}
                                    />
                                </Grid>
                                {(crmData?.status?.statusLabel
                                    ? crmData?.status?.type == 'EMPPLAN'
                                        ? crmData?.status?.slangText
                                        : crmData?.status?.statusLabel
                                    : crmData?.status?.custom_status) === '##Lead' ? (
                                    crmData.lastUpdatedSale?._id && crmData.lastUpdatedSale?.date ? (
                                        <Grid item xs={12} textAlign='center'>
                                            <Typography fontSize='small'>
                                                {t`##LastAction`}: {t`##Transaction`}{' '}
                                                {renderDate(crmData.lastUpdatedSale?.date)}
                                            </Typography>
                                        </Grid>
                                    ) : null
                                ) : crmData?.ringDetails?.length ? (
                                    <Grid item xs={12} textAlign='center'>
                                        <Typography fontSize='small'>
                                            {t`##LastAction`}: {t`##RungOn`}{' '}
                                            {renderDate(crmData?.ringDetails?.[crmData?.ringDetails.length - 1].date)}
                                        </Typography>
                                    </Grid>
                                ) : crmData?.createdAt ? (
                                    <Grid item xs={12} textAlign='center'>
                                        <Typography fontSize='small'>
                                            {t`##LastAction`}: {t`##Created`} {renderDate(crmData?.createdAt)}
                                        </Typography>
                                    </Grid>
                                ) : null}
                                {lastLeadStatus && (
                                    <Grid item xs={12} textAlign='center'>
                                        <Typography fontSize='small'>
                                            {t`##LastLeadStatusAction`}: {renderDate(lastLeadStatus.date)}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>

                            {/* Details Section */}
                            <Grid
                                item
                                container
                                xs={12}
                                sx={{ backgroundColor: '#e9f3f4' }}
                                justifyContent='space-around'
                                alignItems='center'
                                visibility={addNewCRMEntry ? 'hidden' : undefined}
                            >
                                {transactionReportConfig?.customReport && <IconButton
                                    color='secondary'
                                    disabled={newTransactionDisabled}
                                    onClick={() => {
                                        setNewTransactionDisabled(true);
                                        createNewEvent(crmData);
                                    }}
                                >
                                    <AddShoppingCartIcon sx={{ fontSize: '2.8rem' }} />
                                </IconButton>}
                                <IconButton color='secondary' onClick={() => navigate.push(`/crmMessenger/${leadId}`)}>
                                    <Badge
                                        badgeContent={unreadCount > 0 ? unreadCount : totalCountMsg}
                                        overlap='circular'
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                backgroundColor: unreadCount > 0 ? 'default' : '#424242',
                                                color: '#fff', // Ensure text remains readable
                                            },
                                        }}
                                    >
                                        <ChatBubbleIcon sx={{ fontSize: '2.8rem' }} />
                                    </Badge>
                                </IconButton>
                                <IconButton color='secondary' onClick={() => handleClickOpen()}>
                
                                        <HistoryIcon sx={{ fontSize: '2.8rem' }} />
                                 
                                </IconButton>

                                <IconButton
                                    color='secondary'
                                    onClick={() => {
                                        createRingDetails(leadId, projectId, !isHybridSync)
                                            .then((jsonData: any) => {
                                                if (jsonData.success && jsonData.data._id && jsonData.data.ringDetails) {
                                                    setCrmData({
                                                        ...crmData,
                                                        ringDetails: jsonData.data.ringDetails,
                                                    });
                                                }
                                            })
                                            .catch(error => {
                                                console.error('crm ring failed', error);
                                                snackbarTools.createToast({
                                                    type: 'error',
                                                    message: (
                                                        <React.Fragment key='ring-fail-message'>
                                                            {t`##PleaseActivateGPSSettinginBrowser`}
                                                            <br />
                                                            <a
                                                                href='https://www.empplan.com/training-center/empplan-app-quickstart/'
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                            >
                                                                https://www.empplan.com/training-center/empplan-app-quickstart/
                                                            </a>
                                                        </React.Fragment>
                                                    ),
                                                });
                                            });
                                    }}
                                >
                                    <Badge
                                        badgeContent={crmData?.ringDetails?.filter(r => r.projectId === projectId).length}
                                        overlap='circular'
                                    >
                                        <DoorbellIcon sx={{ fontSize: '3.185rem' }} />
                                    </Badge>
                                </IconButton>
                            </Grid>

                            {/* CRM Labels Section */}
                            <Grid visibility={addNewCRMEntry ? 'hidden' : undefined} item>
                                <Typography px={1}>{t`##Labels`}</Typography>
                                <Grid container spacing={1} wrap='wrap' px={1}>
                                    {allLabelData.map(label => (
                                        <Grid item key={label._id}>
                                            <Chip
                                                label={label.statusLabel}
                                                variant='outlined'
                                                color={customStatusIds?.includes(label._id) ? 'secondary' : undefined}
                                                onClick={() => {
                                                    if (customStatusIds?.includes(label._id)) {
                                                        if (isHybridSync) {
                                                            // remove label
                                                            API.post('crm/unsetcustomstatus', {
                                                                crmIds: [crmData._id],
                                                                customstatus: label,
                                                            })
                                                                .then(res => res.json())
                                                                .then(jsonData => {
                                                                    if (jsonData?.success) {
                                                                        const tmp: any = {
                                                                            ...crmData,
                                                                            customStatuses: customStatusIds.filter(
                                                                                l => l !== label._id,
                                                                            ),
                                                                        };
                                                                        setCrmData(tmp as CRMData);
                                                                    }
                                                                });
                                                        } else {
                                                            const updatedCustomStatuses = customStatusIds.filter(
                                                                l => l !== label._id,
                                                            );
                                                            updateLeadCustomStatusesAction(
                                                                crmData._id,
                                                                updatedCustomStatuses,
                                                            ).then(jsonData => {
                                                                if (jsonData?.success) {
                                                                    const tmp: any = {
                                                                        ...crmData,
                                                                        customStatuses: updatedCustomStatuses,
                                                                    };
                                                                    setCrmData(tmp as CRMData);
                                                                }
                                                            });
                                                        }
                                                    } else {
                                                        if (isHybridSync) {
                                                            // add label
                                                            API.post('crm/setcustomstatus', {
                                                                crmIds: [crmData._id],
                                                                customstatus: [label],
                                                            })
                                                                .then(res => res.json())
                                                                .then(jsonData => {
                                                                    if (jsonData?.success) {
                                                                        setCrmData({
                                                                            ...crmData,
                                                                            customStatuses: [...customStatusIds, label._id],
                                                                        });
                                                                    }
                                                                });
                                                        } else {
                                                            const updatedCustomStatuses = [...customStatusIds, label._id];
                                                            updateLeadCustomStatusesAction(
                                                                crmData._id,
                                                                updatedCustomStatuses,
                                                            ).then(jsonData => {
                                                                if (jsonData?.success) {
                                                                    const tmp: any = {
                                                                        ...crmData,
                                                                        customStatuses: updatedCustomStatuses,
                                                                    };
                                                                    setCrmData(tmp as CRMData);
                                                                }
                                                            });
                                                        }
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>

                            {/* Transaction Section */}
                            {transactionData.length > 0 && <Grid visibility={addNewCRMEntry ? 'hidden' : undefined} item container xs={12} px={1}>
                                <LeadDetailTransactionList
                                    transactionData={transactionData}
                                    leadId={leadId}
                                    crmData={crmData}
                                />
                            </Grid>}

                            {/* Generic Fields Section */}
                            <Grid
                                item
                                sx={{
                                    backgroundColor: '#424242',
                                    flexGrow: 1,
                                }}
                                visibility={addNewCRMEntry ? 'hidden' : undefined}
                            >
                                <Grid container wrap='wrap' px={1}>
                                    {/* custom statuses and residential address */}
                                    <Grid container item xs={5} m={1} alignContent='start'>
                                        <Grid item xs={12}>
                                            <Typography variant='body2' color='white'>
                                                {t('##ResidentialUnits')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body2' color='white' fontWeight='bold'>
                                                {crmData.residentialUnit ?? 0}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {relatedGenricFields
                                        .filter(
                                            ({ fieldAttributeName }) =>
                                                crmData.genericFields && crmData.genericFields[fieldAttributeName],
                                        )
                                        .map(gf => (
                                            <Grid container item xs={5} m={1} key={gf._id} alignContent='start'>
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' color='white'>
                                                        {gf.fieldLabelName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' color='white' fontWeight='bold'>
                                                        {crmData.genericFields?.[gf.fieldAttributeName] ?? ' '}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>

                    </div>
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            >
                            <ArrowBackIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t("##CRMHistory")}
                            </Typography>
                        
                        </Toolbar>
                        </AppBar>
                        <List>
                       
                        {crmData?._id && <CommonHistory model='CRM' modelRef={crmData?._id} />}
           
                       
                        </List>
                    </Dialog>
                </React.Fragment>
            )}
        </>
    );
};
